import { Module, createStore } from 'vuex'
import { useRouter } from 'vue-router'
import { RootState } from './index'
import { RegularGridItem, TriangularGridItem, HexagonalGridItem } from '@/types/shapes'

interface ColorInfo {
  color: string;
  name: string;
  number: number;
  total: number;
}

export interface ControlState {
  selectedTool: string
  selectedColor: ColorInfo
  viewMode: string
  selectedShape: string
  regularGrid: RegularGridItem[]
  triangularGrid: TriangularGridItem[]
  hexagonalGrid: HexagonalGridItem[]
}

const controlModule: Module<ControlState, RootState> = {
  state: {
    selectedTool: 'paint',
    viewMode: 'Flat',
    selectedColor: { color: '#FFFFFF', name: 'White', number: 0, total: 0 },
    selectedShape: 'Square',
    regularGrid: [],
    triangularGrid: [],
    hexagonalGrid: []
  },
  mutations: {
    setSelectedTool (state, tool: string) {
      state.selectedTool = tool
    },
    setSelectedColor (state, color: ColorInfo) {
      state.selectedColor = color
    },
    setViewMode (state, mode: string) {
      state.viewMode = mode
    },
    setSelectedShape (state, shape: string) {
      state.selectedShape = shape
    },
    setRegularGrid (state, grid: RegularGridItem[]) {
      state.regularGrid = grid
    },
    setTriangularGrid (state, grid: TriangularGridItem[]) {
      state.triangularGrid = grid
    },
    setHexagonalGrid (state, grid: HexagonalGridItem[]) {
      state.hexagonalGrid = grid
    }
  },
  actions: {
    selectTool ({ commit }, tool: string) {
      commit('setSelectedTool', tool)
    },
    selectColor ({ commit }, color: ColorInfo) {
      commit('setSelectedColor', color)
    },
    setViewMode ({ commit }, mode: string) {
      commit('setViewMode', mode)
    },
    selectShape ({ commit }, shape: string) {
      commit('setSelectedShape', shape)
    },
    saveRegularGrid ({ commit }, grid: RegularGridItem[]) {
      commit('setRegularGrid', grid)
    },
    saveTriangularGrid ({ commit }, grid: TriangularGridItem[]) {
      commit('setTriangularGrid', grid)
    },
    saveHexagonalGrid ({ commit }, grid: HexagonalGridItem[]) {
      commit('setHexagonalGrid', grid)
    }
  },
  getters: {
    getSelectedTool: (state) => state.selectedTool,
    getSelectedColor: (state) => state.selectedColor,
    getViewMode: (state) => state.viewMode,
    getSelectedShape: (state) => state.selectedShape,
    getRegularGrid: (state) => state.regularGrid,
    getTriangularGrid: (state) => state.triangularGrid,
    getHexagonalGrid: (state) => state.hexagonalGrid,
    getCurrentGrid: (state) => {
      switch (state.selectedShape) {
        case 'Triangle':
          return state.triangularGrid
        case 'Hexagon':
          return state.hexagonalGrid
        default:
          return state.regularGrid
      }
    }
  }
}

export default controlModule
